import React, { useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import update from "immutability-helper";
import FormElement from "./FormElement";
import {
  FaTextWidth,
  FaAlignLeft,
  FaCheckSquare,
  FaDotCircle,
  FaCaretSquareDown,
} from "react-icons/fa";

const availableElements = [
  { type: "text", label: "Text Input", icon: <FaTextWidth /> },
  { type: "textarea", label: "Text Area", icon: <FaAlignLeft /> },
  { type: "checkbox", label: "Checkbox", icon: <FaCheckSquare /> },
  { type: "radio", label: "Radio Button", icon: <FaDotCircle /> },
  { type: "select", label: "Select Dropdown", icon: <FaCaretSquareDown /> },
];

const AvailableElement = ({ element, addElement }) => {
  return (
    <div
      onClick={() => addElement(element.type)}
      className="form-element p-2 border rounded cursor-pointer flex items-center space-x-2 bg-white shadow-sm hover:bg-gray-100 transition"
    >
      {element.icon}
      <span>{element.label}</span>
    </div>
  );
};

const FormBuilder = ({ formData, setFormData }) => {
  const [elements, setElements] = useState([]);

  useEffect(() => {
    setElements(formData);
  }, [formData]);

  const addElementToForm = (type) => {
    const newElement = {
      id: new Date().getTime(),
      type,
      label: `${type} label`,
      options: type === "radio" || type === "select" ? [""] : undefined,
      value: "",
      required: false,
    };
    setElements((prevElements) => [...prevElements, newElement]);
    setFormData((prevData) => [...prevData, newElement]);
  };

  const moveElement = (fromId, toId) => {
    const fromIndex = elements.findIndex((el) => el.id === fromId);
    const toIndex = elements.findIndex((el) => el.id === toId);
    if (
      fromIndex !== -1 &&
      toIndex !== -1 &&
      !elements[fromIndex].disabled &&
      !elements[toIndex].disabled
    ) {
      const updatedElements = update(elements, {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, elements[fromIndex]],
        ],
      });
      setElements(updatedElements);
      setFormData(updatedElements);
    }
  };

  const updateElement = (id, updatedElement) => {
    const updatedElements = elements.map((el) =>
      el.id === id ? updatedElement : el
    );
    setElements(updatedElements);
    setFormData(updatedElements);
  };

  const deleteElement = (id) => {
    const updatedElements = elements.filter((el) => el.id !== id);
    setElements(updatedElements);
    setFormData(updatedElements);
  };

  const [{ isOver }, drop] = useDrop({
    accept: "form-element",
    hover: () => {},
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div className="form-builder flex flex-col md:flex-row">
      <div
        className="available-elements md:w-1/3 w-full space-y-2 bg-gray-50 md:bg-transparent mb-4 md:mb-0"
        style={{ marginRight: 40 }}
      >
        {availableElements.map((el) => (
          <AvailableElement
            key={el.type}
            element={el}
            addElement={addElementToForm}
          />
        ))}
      </div>
      <div
        ref={drop}
        className={`form-drop-zone md:w-2/3 w-full p-4 border rounded ${
          isOver ? "bg-gray-100" : "bg-white"
        } border-dashed border-2`}
        style={{ minHeight: "400px" }}
      >
        {elements.length === 0 && (
          <div className="text-center text-gray-400 py-20">
            Add Form Fields Here
          </div>
        )}
        {elements.map((el, index) => (
          <FormElement
            key={el.id}
            element={el}
            index={index}
            moveElement={moveElement}
            updateElement={updateElement}
            deleteElement={deleteElement}
          />
        ))}
      </div>
    </div>
  );
};

export default FormBuilder;
