import React, { useRef, useState, useEffect } from "react";
import { FaCamera, FaUpload, FaCloudUploadAlt } from "react-icons/fa";
import Webcam from "react-webcam";
import Swal from "sweetalert2";

const SelfieUpload = ({ setSelfie, setPreview }) => {
  const fileInputRef = useRef(null);
  const webcamRef = useRef(null);
  const [preview, setPreviewState] = useState(null);
  const [captureMode, setCaptureMode] = useState("camera");
  const [isMobile, setIsMobile] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setSelfie(file);
      setPreview(previewUrl);
      setPreviewState(previewUrl);
    }
  };

  const handleRetake = () => {
    setPreviewState(null);
    setPreview(null);
    setSelfie(null);
  };

  const handleCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });

      if (stream) {
        setPermissionDenied(false);
        const screenshot = webcamRef.current.getScreenshot({
          width: 1000,
          height: 1000,
        });
        setPreviewState(screenshot);
        setPreview(screenshot);
        fetch(screenshot)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "selfie.png", { type: "image/png" });
            setSelfie(file);
          });
        stream.getTracks().forEach((track) => track.stop());
      }
    } catch (error) {
      setPermissionDenied(true);
      promptCameraAccess();
    }
  };

  // Function to prompt the user with an alert if camera access is denied
  const promptCameraAccess = async () => {
    Swal.fire({
      icon: "error",
      title: "Camera Access Denied",
      text: "Please allow camera access to take a selfie.",
    });

    // Start checking for permission status changes
    const permissionCheckInterval = setInterval(async () => {
      const permissionStatus = await navigator.permissions.query({
        name: "camera",
      });

      if (permissionStatus.state === "granted") {
        setPermissionDenied(false);
        clearInterval(permissionCheckInterval);
      }
    }, 1000); // Check every second
  };

  const toggleCaptureMode = (mode) => {
    if (mode === "camera") {
      // If "Upload a Selfie" is already active, take the picture
      if (captureMode === "camera") {
        handleCapture();
      } else {
        // Otherwise, just toggle the mode and reset the state
        setCaptureMode(mode);
        handleRetake();
        setPermissionDenied(false);
      }
    } else {
      // Handle the "Upload from Device" button click
      setCaptureMode(mode);
      handleRetake();
    }
  };

  return (
    <div className="mb-4 text-center flex flex-col items-center">
      <label
        className="block text-gray-700 text-sm font-bold"
        style={{ marginTop: 20, marginBottom: 10 }}
      >
        Upload a Selfie *
      </label>
      <div
        className={`flex ${
          isMobile ? "flex-col space-y-2" : "flex-row space-x-2"
        } justify-center mb-2`}
      >
        <button
          type="button"
          onClick={() => toggleCaptureMode("camera")}
          className={`px-4 py-2 flex items-center justify-center ${
            captureMode === "camera"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          } rounded`}
        >
          <FaCamera className="mr-2" />
          Take a Selfie
        </button>
        <button
          type="button"
          onClick={() => toggleCaptureMode("upload")}
          className={`px-4 py-2 flex items-center justify-center ${
            captureMode === "upload"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          } rounded`}
        >
          <FaUpload className="mr-2" />
          Upload from Device
        </button>
      </div>
      {captureMode === "camera" && (
        <div className="flex flex-col items-center">
          {preview ? (
            <>
              <div
                className="mt-2 mx-auto"
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: "50%",
                  backgroundImage: `url(${preview})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <button
                type="button"
                onClick={handleRetake}
                className="mt-2 text-red-500 underline"
                style={{ marginTop: 15 }}
              >
                Re-take Photo
              </button>
            </>
          ) : (
            <div
              className="relative mx-auto"
              style={{
                width: 300,
                height: 300,
                margin: "15px 0 0 0",
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                backgroundColor: permissionDenied ? "black" : "transparent",
              }}
              onClick={permissionDenied ? promptCameraAccess : undefined}
            >
              {permissionDenied ? (
                <div
                  style={{
                    color: "white",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Please allow camera access
                </div>
              ) : (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  videoConstraints={{ width: 1000, height: 1000 }}
                  onClick={handleCapture}
                  onUserMediaError={() => setPermissionDenied(true)}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          )}
          {!preview && !permissionDenied && (
            <button
              type="button"
              onClick={handleCapture}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
            >
              Capture Photo
            </button>
          )}
        </div>
      )}
      {captureMode === "upload" && (
        <>
          <input
            type="file"
            onChange={handleFileChange}
            className="hidden"
            id="selfieUpload"
            accept="image/*"
            ref={fileInputRef}
          />
          <div
            onClick={handleClick}
            className="cursor-pointer inline-block mx-auto"
            style={{ marginBottom: 20 }}
          >
            {preview ? (
              <>
                <div
                  className="mt-2 mx-auto"
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: "50%",
                    backgroundImage: `url(${preview})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <button
                  type="button"
                  onClick={handleRetake}
                  className="mt-2 text-red-500 underline"
                  style={{ marginTop: 15 }}
                >
                  Re-take Photo
                </button>
              </>
            ) : (
              <div
                className="mt-2 flex items-center justify-center bg-gray-200 text-gray-500 rounded-full mx-auto"
                style={{ width: 100, height: 100 }}
              >
                <FaCloudUploadAlt size={32} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SelfieUpload;
